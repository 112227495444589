import React from 'react';
import SubscriptionForm from './SubscriptionForm';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="App-footer">
      <div className="footer-content">
        <SubscriptionForm />
{/*        <div className="social-links">
          <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link">LinkedIn</a>
          <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link">Instagram</a>
        </div>*/}
      </div>
    </footer>
  );
}

export default Footer;