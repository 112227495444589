import React from 'react';
import Header from './Header';
import Footer from './Footer';

import './About.css';

const AboutMe = () => {
  return (
    <div>
      <Header />
      <div className="about-content">
  
      <h1> Nate Loker </h1>
      <img src="/headshot.jpg" alt="Nate" className="profile-picture" />
      <p>
      As a breath coach, software engineer, and movement enthusiast, 
      I am dedicated to helping my clients enhance their lives by teaching 
      them how to regulate their nervous systems and optimize stress. 
      One of the most effective approaches I've discovered is by teaching
      clients to breathe better. 
      </p> 
      <p>
       I am currently an advanced instructor in the Oxygen Advantage method, 
       directed by Patrick McKeown. This specialized training has equipped me with 
       the knowledge and skills to help individuals master their breathing 
       and achieve greater overall well-being. 
      </p>
      <p>
        My journey into the world of health and well-being
        is driven by my own experience overcoming chronic back pain.
        I've explored and tried numerous health modalities,
        which has instilled in me a strong belief in providing 
        the best possible health guidance to my clients. 
        This first-hand understanding allows me to empathize with their
        struggles and tailor my approach to their unique needs. 
      </p>
      </div>
      <Footer />
    </div>

  );
}

export default AboutMe;