import React from 'react';
import Header from './Header';
import Footer from './Footer';

import './Coaching.css';

const Coaching = () => {
  return (
    <div>
      <Header />
      <div className="coaching-content">
        <h1> Private Coaching $200/month</h1>
        <h2> Includes: </h2>
        <ul>
          <li> Comprehensive initial assessment </li>
          <li> Weekly 30 min progress call</li>
          <li> Individualized breathing exercises and protocols. </li>
          <li> Simple scientific breathing protocols to improve concentration and focus 
          while reducing stress </li>
          <li> Learn how to optimize stress and regulate your nervous system </li>
          <li> SMART goals to measure progress and hold yourself accountable </li>

        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Coaching;
