import React from 'react';
import Header from './Header';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <Header />

      <div className="hero-section">
        <div className="overlay-text">
          <h1>Improve Performance, Stress Less</h1>
          <p>Simple, Scientifically Proven Techniques to Breathe Better and Improve Performance.</p>
        </div>
        
        {/* Free Consultation Box inside Hero Section */}
        <div className="consultation-box">
          <a href="https://calendly.com/breath-assessment" target="_blank" rel="noopener noreferrer" className="consultation-link">
            Free Consultation
          </a>
        </div>
      </div>

    </div>
  );
}

export default Home;