import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact'
import About from './About'
import Education from './Education'
import ArticleDetail from './ArticleDetail'
import Coaching from './Coaching'


import './App.css'; // Temporarily commented out for troubleshooting

function App() {
  return (
    <Router>
      <div className="App">
        <Routes> {/* Wrap Route components inside Routes */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/About" element={<About />}/>
          <Route exact path="/Coaching" element={<Coaching />}/>
          <Route exact path="/education" element={<Education />}/>
          <Route exact path="/education/:articleId" element={<ArticleDetail />} />


          {/* other routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;